import request from '../common/utils';


export const getProjectsApi = (params, signal) => request({
  url: '/projects',
  method: 'GET',
  params,
  signal,
});

export const getLightProjectsApi = (params, signal) => request({
  url: '/map/projects',
  method: 'GET',
  params,
  signal,
});

export const createProjectApi = (data) => request({
  url: '/projects',
  method: 'POST',
  data,
});

export const editProjectApi = (data) => request({
  url: `/projects/${data.id}`,
  method: 'PUT',
  data,
});

export const deleteProjectApi = ({ id }) => request({
  url: `/projects/${id}`,
  method: 'DELETE',
});

export const getProjectApi = (id, signal) => request({
  url: `/projects/${id}`,
  method: 'GET',
  signal,
});

export const getUnitsApi = (projectId, params, signal) => request({
  url: `/projects/${projectId}/units`,
  method: 'GET',
  params,
  signal,
});

export const downloadUnitsApi = (projectId) => request({
  url: `/projects/${projectId}/units/downloads`,
  method: 'POST',
  responseType: 'blob',
});

export const uploadUnitsApi = (projectId, data) => request({
  url: `/projects/${projectId}/units/import`,
  method: 'POST',
  headers: {
    'content-type': 'multipart/form-data',
  },
  data,
});

export const syncUnitsApi = (projectId, data) => request({
  url: `/projects/${projectId}/units/sync`,
  method: 'POST',
  data,
});

export const unitsScanApi = ({ projectId, data }) => request({
  url: `/projects/${projectId}/units/scan`,
  method: 'POST',
  headers: {
    'content-type': 'multipart/form-data',
  },
  data,
});

export const getUnitTypesApi = () => request({
  url: '/unitTypes',
  method: 'GET',
});

export const getUnitLayoutsApi = () => request({
  url: '/unitLayouts',
  method: 'GET',
});

export const getAmenitiesApi = () => request({
  url: '/amenities',
  method: 'GET',
});

export const syncAmenitiesApi = (projectId, data) => request({
  url: `projects/${projectId}/amenities/sync`,
  method: 'POST',
  data,
});

export const getProjectAmenitiesApi = (projectId) => request({
  url: `projects/${projectId}/amenities`,
  method: 'GET',
});

export const getPaymentPlansApi = (id) => request({
  url: `paymentPlans${id ? `/${id}` : ''}`,
  method: 'GET',
});

export const createPaymentPlanApi = (data) => request({
  url: '/paymentPlans',
  method: 'POST',
  data,
});

export const editPaymentPlanApi = (data) => request({
  url: `/paymentPlans/${data.id}`,
  method: 'PUT',
  data,
});

export const deletePaymentPlanApi = ({ id }) => request({
  url: `/paymentPlans/${id}`,
  method: 'DELETE',
});

export const createPaymentPlanItemApi = (data) => request({
  url: `/paymentPlans/${data.paymentPlanId}/items`,
  method: 'POST',
  data,
});

export const editPaymentPlanItemApi = (data) => request({
  url: `/paymentPlans/${data.paymentPlanId}/items/${data.id}`,
  method: 'PUT',
  data,
});

export const deletePaymentPlanItemApi = (data) => request({
  url: `/paymentPlans/${data.paymentPlanId}/items/${data.id}`,
  method: 'DELETE',
});

export const getProjectPaymentPlansApi = (projectId) => request({
  url: `projects/${projectId}/paymentPlans`,
  method: 'GET',
});

export const syncProjectPaymentPlansApi = (projectId, data) => request({
  url: `projects/${projectId}/paymentPlans/sync`,
  method: 'POST',
  data,
});

export const syncProjectFinishesApi = (projectId, data) => request({
  url: `projects/${projectId}/finishes/sync`,
  method: 'POST',
  data,
});

export const getProjectFinishesApi = (projectId) => request({
  url: `projects/${projectId}/finishes`,
  method: 'GET',
});

export const getFinishesApi = () => request({
  url: '/finishes',
  method: 'GET',
});

export const getProjectDocumentsApi = ({ projectId, params }) => request({
  url: `projects/${projectId}/files`,
  method: 'GET',
  params,
});

export const getConfigApi = () => request({
  url: '/config',
  method: 'GET',
});

export const addToFavoriteApi = (id) => request({
  url: `/favorite/${id}`,
  method: 'POST',
});

export const deleteFromFavoriteApi = (id) => request({
  url: `/favorite/${id}`,
  method: 'DELETE',
});
