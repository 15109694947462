import { createSlice } from '@reduxjs/toolkit';

import {
  getDistricts,
  createDistrict,
  editDistrict,
  getDistrictDevelopers,
  getDistrictImages,
  getDistrict,
  deleteDistrict,
} from './thunks';


const initialState = {
  data: {
    districts: [],
    district: {
      data: null,
      developers: [],
      images: [],
    },
    selected: null,
  },
  meta: {},
  error: null,
};

const reducers = {
  setSelected: ({ data }, { payload }) => {
    data.selected = payload;
    data.district = {};
  },
};

const extraReducers = (builder) => builder
  .addCase(getDistricts.fulfilled, ({ data }, { payload }) => {
    data.districts = payload;
  })

  .addCase(getDistrict.fulfilled, ({ data: { district } }, { payload }) => {
    district.data = payload;
  })

  .addCase(createDistrict.fulfilled, ({ data }, { payload, meta }) => {
    data.districts = data.districts.filter(({ id }) => id !== meta?.arg?.id);
    data.districts.push(payload);
  })

  .addCase(editDistrict.fulfilled, ({ data }, { payload }) => {
    const index = data.districts.findIndex(({ id }) => id === payload.id);
    data.districts[index] = payload;
  })

  .addCase(deleteDistrict.fulfilled, ({ data }, { payload }) => {
    data.districts = data.districts.filter(({ id }) => id !== payload.id);
  })

  .addCase(getDistrictDevelopers.fulfilled, ({ data: { district } }, { payload }) => {
    district.developers = payload;
  })

  .addCase(getDistrictImages.fulfilled, ({ data: { district } }, { payload }) => {
    district.images = payload;
  });

export const { actions, reducer, name } = createSlice({
  name: 'districts',
  initialState,
  reducers,
  extraReducers,
});
