import { configureStore } from '@reduxjs/toolkit';

import { reducer as emirates } from './emirates/reducers';
import { reducer as districts } from './districts/reducers';
import { reducer as projects } from './projects/reducers';
import { reducer as companies } from './companies/reducers';
import { reducer as employees } from './employees/reducers';
import { reducer as filters } from './filters/reducers';
import { reducer as catalog } from './catalog/reducers';


export default configureStore({
  reducer: {
    emirates,
    districts,
    projects,
    companies,
    employees,
    filters,
    catalog,
  },
  devTools: process.env.NODE_ENV === 'development',
});
