import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDistrictsApi,
  createDistrictApi,
  editDistrictApi,
  getDistrictDevelopersApi,
  getDistrictImagesApi,
  getDistrictApi,
  deleteDistrictApi,
} from './api';


export const getDistricts = createAsyncThunk(
  'districts/get',
  async (payload) => {
    const { data } = await getDistrictsApi(payload);

    return data;
  },
);

export const getDistrict = createAsyncThunk(
  'district/get',
  async (id) => {
    const { data } = await getDistrictApi(id);

    return data;
  },
);

export const createDistrict = createAsyncThunk(
  'districts/create',
  async (district) => {
    const { data } = await createDistrictApi(district);

    return data;
  },
);

export const editDistrict = createAsyncThunk(
  'districts/edit',
  async (district) => {
    const { data } = await editDistrictApi(district);

    return data;
  },
);

export const deleteDistrict = createAsyncThunk(
  'districts/delete',
  async (district) => {
    if (!district.isNew) await deleteDistrictApi(district);

    return district;
  },
);

export const getDistrictDevelopers = createAsyncThunk(
  'districts/getDevelopers',
  async ({ districtId }) => {
    const { data } = await getDistrictDevelopersApi({ districtId });

    return data;
  },
);

export const getDistrictImages = createAsyncThunk(
  'districts/getImages',
  async ({ districtId }) => {
    const { data } = await getDistrictImagesApi({ districtId });

    return data;
  },
);
