import { createNextState, createSlice } from '@reduxjs/toolkit';

import { downloadFile } from 'components/utils';

import {
  getProjects,
  createProject,
  editProject,
  getProjectUnits,
  uploadProjectUnits,
  syncAmenities,
  syncProjectFinishes,
  getProject,
  deleteProject,
  editPaymentPlan,
  deletePaymentPlan,
  editPaymentPlanItem,
  deletePaymentPlanItem,
  getConfig,
  syncProjectPaymentPlans,
  downloadUnits,
  addToFavorite,
  deleteFromFavorite,
} from './thunks';


const initialState = {
  data: {
    projects: [],
    project: {
      data: null,
      units: [],
    },
    unitTypes: [],
    unitLayouts: [],
    amenities: [],
    paymentPlans: [],
    finishes: [],
    selected: null,
    socialNetworks: [],
  },
  meta: {},
  error: null,
};

const reducers = {
  setSelected: ({ data }, { payload }) => {
    data.selected = payload;
    data.project = {
      data: null,
      units: [],
    };
  },
  addProject: ({ data }, { payload }) => {
    data.projects.push(payload);
  },
  updateProject: ({ data }, { payload }) => {
    data.projects.splice(data.projects.findIndex((arrow) => arrow.id === payload.id), 1);
    data.projects.push(payload);
  },
  removeProject: ({ data }, { payload }) => {
    data.projects.splice(data.projects.findIndex((arrow) => arrow.id === payload.id), 1);
  },
};

const extraReducers = (builder) => builder
  .addCase(getProjects.fulfilled, ({ data }, { payload }) => {
    data.projects = payload;
  })

  .addCase(getProject.fulfilled, ({ data }, { payload }) => {
    data.project.data = createNextState(payload, (draft) => {
      draft.finishingIds = draft?.finishes?.map(({ id }) => id);
    });
  })

  .addCase(createProject.fulfilled, ({ data }, { payload }) => {
    data.projects.push(payload);
    data.selected = payload;
  })

  .addCase(editProject.fulfilled, ({ data }, { payload }) => {
    const index = data.projects.findIndex(({ id }) => id === payload.id);
    data.projects[index] = payload;
  })

  .addCase(deleteProject.fulfilled, ({ data }, { payload }) => {
    data.projects = data.projects.filter(({ id }) => id !== Number(payload.id));
  })

  .addCase(getProjectUnits.fulfilled, ({ data: { project } }, { payload }) => {
    project.units = payload;
  })

  .addCase(uploadProjectUnits.fulfilled, ({ data: { project, projects } }, { payload }) => {
    project.units = payload;
    projects.find(({ id }) => project.data.id === id).unitsUpdatedAt = new Date().toISOString();
  })

  .addCase(syncAmenities.fulfilled, ({ data: { project } }, { payload }) => {
    project.data.amenities = payload;
  })

  .addCase(editPaymentPlan.fulfilled, ({ data: { project } }, { payload }) => {
    const index = project.data.paymentPlans.findIndex(({ id }) => id === payload.id);
    project.data.paymentPlans[index] = payload;
  })

  .addCase(deletePaymentPlan.fulfilled, ({ data: { project } }, { payload }) => {
    project.data.paymentPlans = project.data.paymentPlans.filter(({ id }) => id !== payload.id);
  })

  .addCase(editPaymentPlanItem.fulfilled, ({ data: { project } }, { payload }) => {
    const paymentPlan = project?.data?.paymentPlans?.find(({ id }) => id === payload.paymentPlanId);
    const index = paymentPlan?.items?.findIndex(({ id }) => id === payload.id);
    if (index >= 0) {
      paymentPlan.items[index] = payload;
    }
  })

  .addCase(deletePaymentPlanItem.fulfilled, ({ data: { project } }, { payload }) => {
    const paymentPlan = project.data.paymentPlans.find(({ id }) => id === payload.paymentPlanId);
    paymentPlan.items = paymentPlan.items.filter(({ id }) => id !== payload.id);
  })

  .addCase(syncProjectPaymentPlans.fulfilled, ({ data: { project } }, { payload }) => {
    project.data.paymentPlans = payload;
  })

  .addCase(syncProjectFinishes.fulfilled, ({ data: { project } }, { payload }) => {
    project.data.finishes = payload;
  })

  .addCase(getConfig.fulfilled, (state, { payload }) => {
    state.data = {
      ...state.data,
      ...payload,
    };
  })

  .addCase(downloadUnits.fulfilled, (_, { payload }) => {
    downloadFile(payload, 'units.csv');
  })

  .addCase(addToFavorite.fulfilled, ({ data: { projects, project } }, { payload }) => {
    const proj = projects.find(({ id }) => id === payload);
    if (proj) proj.isFavorite = true;
    project.data.isFavorite = true;
  })

  .addCase(deleteFromFavorite.fulfilled, ({ data: { projects, project } }, { payload }) => {
    projects.find(({ id }) => id === payload).isFavorite = false;
    project.data.isFavorite = false;
  });

export const { actions, reducer, name } = createSlice({
  name: 'projects',
  initialState,
  reducers,
  extraReducers,
});
